<template>
    <div class="block-ui">
        <div class="block-ui-overlay"></div>
        <div class="block-ui-content">
            <div class="spinner mb-3"></div>
            <p class="message">{{ message }}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: "BlockUI",
    props: {
        message: {
            type: String,
            default: "Carregando aguarde...",
        },
    },
};
</script>

<style scoped>
.block-ui {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.block-ui-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.block-ui-content {
    position: relative;
    z-index: 10000;
    text-align: center;
    color: #ffffff;
}

.message {
    font-size: 16px;
    font-weight: bold;
}
</style>