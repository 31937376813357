<template>
  <div class=""
    style="display: flex;justify-content: space-between;align-items: center; cursor:pointer">
    <div class="flex" style="align-items: center;gap: 0.5rem;" @click="$emit('toggle-accounts')">
      <div>
        <div>
          <img class="logo-thumbnail-svg2" :src="logoSource"/>
        </div>
      </div>
      <div v-if="!sidebar.state.isCollapsed || sidebar.state.isHovered">
        <div>
          <div class="gov-chat">{{ $t('SIDEBAR.CURRENTLY_VIEWING_ACCOUNT') }}</div>
        </div>
        <div>
          <div class="enterprise">{{ account.name }}</div>
        </div>
      </div>
    </div>
    <div style="display: flex;flex-direction: column;cursor:pointer" v-if="!sidebar.state.isCollapsed || sidebar.state.isHovered" @click="$emit('toggle-accounts')">
      <div style="font-size: small;height: 8px;color:#3aaa35">
        <i class="fas fa-chevron-up"></i>
      </div>
      <div style="font-size: small;color:#3aaa35">
        <i class="fas fa-chevron-down"></i>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { sidebarStore as sidebar } from "./store";
export default {
  data() {
    return { showSwitchButton: false,sidebar};
  },
  props: {
    logoSource: {
      type: String,
      default: '',
    }
  },
  mounted() {
    this.sidebar.dispatch("checkScreenSize");
    window.addEventListener("resize", () => this.sidebar.dispatch("checkScreenSize"));
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => this.sidebar.dispatch("checkScreenSize"));
  },
  computed: {
    ...mapGetters({
      account: 'getCurrentAccount',
      userAccounts: 'getUserAccounts',
    }),
    showShowCurrentAccountContext() {
      return this.userAccounts.length > 1 && this.account.name;
    },
    checkScreenSize() {
      return window.innerWidth <= 768; // Se for menor que 768px, colapsa o menu
    }
  },
  methods: {
    setShowSwitch() {
      this.showSwitchButton = true;
    },
    resetShowSwitch() {
      this.showSwitchButton = false;
    },
  },
};
</script>
<style scoped>
.logo-thumbnail-svg-fill {
  width: 33px;
  height: 32px;
  position: absolute;
  left: 0px;
  top: 0px;
  overflow: hidden;
}

.logo-thumbnail-svg2 {
  width: 32px;
  height: 32px;
}

.gov-chat {
  color: #3aaa35;
  /*font-family: "Montserrat-SemiBold", sans-serif;*/
  font-size: 14px;
  font-weight: 600;
}

.enterprise {
  color: #3aaa35;
  /*font-family: "Inter-Light", sans-serif;*/
  font-size: 14px;
  font-weight: 300;
}
</style>
