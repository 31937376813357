<template>
  <woot-button
    :size="size"
    variant="clear"
    color-scheme="secondary"
    class="-ml-3 text-black-900 dark:text-slate-300"
    icon="list"
    @click="onMenuItemClick"
  />
</template>

<script>
import { BUS_EVENTS } from 'shared/constants/busEvents';
import { sidebarStore as sidebar } from "./layout/sidebarComponents/store";
export default {
  data(){
    return{
      sidebar
    }
  },
  props: {
    size: {
      type: String,
      default: 'small',
    },
  },
  methods: {
    onMenuItemClick() {
      this.sidebar.dispatch("toogleSider")
      bus.$emit(BUS_EVENTS.TOGGLE_SIDEMENU);
    },
  },
};
</script>
