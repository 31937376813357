<template>
  <div class="w-full">
    <button
      class="w-full noti dark:text-slate-100 flex items-center rounded-lg hover:bg-slate-25 dark:hover:bg-slate-700 dark:hover:text-slate-100 relative"
      :class="{
        'bg-woot-50 dark:bg-slate-800 text-woot-500 hover:bg-woot-50':
          isNotificationPanelActive,
      }" @click="openNotificationPanel" style="color:rgb(63, 63, 70);cursor:pointer">
      <div class="mr-2">
        <fluent-icon icon="alert" :class="{
          'text-woot-500': isNotificationPanelActive,
        }" size="30"/>
        <span v-if="unreadCount" class="text-black-900 bg-yellow-300 absolute -top-0.5 p-1 text-xxs min-w-[1rem] rounded-full">
          {{ unreadCount }}
        </span>
      </div>
      <div v-if="!sidebar.state.isCollapsed || sidebar.state.isHovered">
        <p class="mb-0">
          Notificações
        </p>
      </div>
    </button>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { sidebarStore as sidebar } from "./store";

export default {
  data() {
    return {
      sidebar
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      notificationMetadata: 'notifications/getMeta',
    }),
    unreadCount() {
      if (!this.notificationMetadata.unreadCount) {
        return '';
      }

      return this.notificationMetadata.unreadCount < 100
        ? `${this.notificationMetadata.unreadCount}`
        : '99+';
    },
    isNotificationPanelActive() {
      return this.$route.name === 'notifications_index';
    },
  },
  mounted() {
    this.sidebar.dispatch("checkScreenSize");
    window.addEventListener("resize", () => this.sidebar.dispatch("checkScreenSize"));
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => this.sidebar.dispatch("checkScreenSize"));
  },
  methods: {
    openNotificationPanel() {
      if (this.$route.name !== 'notifications_index') {
        this.$emit('open-notification-panel');
      }
    },
  },
};
</script>
<style scoped>
html[style="color-scheme: dark;"] .noti {
  color: #ccc !important;
  /* Cor do texto da classe 'conversas' no tema escuro */
}
</style>
