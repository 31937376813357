<template>
  <div class="property-1-variant-5 bg-white" :class="{ collapsed: sidebar.state.isCollapsed && !sidebar.state.isHovered }"
    @mouseenter="sidebar.commit('SET_HOVERED', true)" 
    @mouseleave="sidebar.commit('SET_HOVERED', false)"
    style="display: flex;flex-direction: column;justify-content: space-between;">
    <div style="display: flex;flex-direction: column; height: 100vh;overflow: hidden;" :style="sidebar.state.isCollapsed && !sidebar.state.isHovered ? 'align-items: center;' : ''">

      <account-context @toggle-accounts="toggleAccountModal" :logoSource="logoSource" style="padding: 0.9rem;" />
      <div class="container3">
        <div style="display: flex;flex-direction: column;">
          <div class="servi-os" v-if="!sidebar.state.isCollapsed || sidebar.state.isHovered">Serviços</div>
          <div class="frame-427320933">
            <div class="item2" v-for="(menuItem, index) in menuItems" :key="index">
              <div style="display: flex;flex-direction: column;">
                <router-link :to="menuItem.toState">
                  <div class="frame-427320935" style="justify-content: space-between; cursor:pointer;color: #3F3F46;"
                    @click="toggleSubmenu(menuItem.toState)">
                    <div class="flex" style="gap: 0.5rem;">
                      <div>
                        <fluent-icon :icon="menuItem.icon" class="icone"/>
                      </div>
                      <div class="conversas" v-if="!sidebar.state.isCollapsed || sidebar.state.isHovered">{{ $t(`SIDEBAR.${menuItem.label}`) }}
                      </div>
                    </div>
                    <div>
                      <div v-if="!sidebar.state.isCollapsed || sidebar.state.isHovered">
                        <i class="fas fa-chevron-right"
                          :class="{ rotated: openIndexes.includes(menuItem.toState) }"></i>
                      </div>
                    </div>
                  </div>
                </router-link>
                <div class="list2" v-if="openIndexes.includes(menuItem.toState) && (!sidebar.state.isCollapsed || sidebar.state.isHovered)" style="margin-left: 0.5rem;">
                  <transition-group name="menu-list" tag="ul" class="pt-2 list-none ml-0 mb-0">
                    <secondary-nav-item v-for="menuItem in accessibleMenuItems" :key="menuItem.toState"
                      :menu-item="menuItem" />
                    <secondary-nav-item v-for="menuItem in additionalSecondaryMenuItems[menuConfig.parentNav]"
                      :key="menuItem.key" :menu-item="menuItem" @add-label="showAddLabelPopup" />
                  </transition-group>
                </div>
                <!--
                <i class="fas fa-comment-alt svg3"></i>
                <fluent-icon :icon="menuItem.icon" />
                -->
              </div>
            </div>
          </div>
          <!--
                -->
        </div>
      </div>
    </div>
    <div class="frame-427320940 flex" style="padding:0.9rem;gap: 0.9em;flex-direction: column;">
      <div class="background w-full">
        <notification-bell @open-notification-panel="openNotificationPanel" />
      </div>
      <div class="flex" style="align-items: center;">
        <agent-details @toggle-menu="toggleOptions" />
      </div>
      <options-menu :show="showOptionsMenu" @toggle-accounts="toggleAccountModal"
        @show-support-chat-window="toggleSupportChatWindow" @key-shortcut-modal="$emit('key-shortcut-modal')"
        @close="toggleOptions" />
    </div>
  </div>
</template>

<script>
import Logo from './Logo.vue';
import PrimaryNavItem from './PrimaryNavItem.vue';
import OptionsMenu from './OptionsMenu.vue';
import AgentDetails from './AgentDetails.vue';
import NotificationBell from './NotificationBell.vue';
import wootConstants from 'dashboard/constants/globals';
import { frontendURL } from 'dashboard/helper/URLHelper';
import { ACCOUNT_EVENTS } from '../../../helper/AnalyticsHelper/events';
import AccountContext from './AccountContext.vue';
import SecondaryNavItem from './SecondaryNavItem.vue';
import { mapGetters } from 'vuex';
import { FEATURE_FLAGS } from '../../../featureFlags';
import { sidebarStore as sidebar } from "./store";

export default {
  components: {
    Logo,
    PrimaryNavItem,
    OptionsMenu,
    AgentDetails,
    NotificationBell,
    AccountContext,
    SecondaryNavItem
  },
  props: {
    isACustomBrandedInstance: {
      type: Boolean,
      default: false,
    },
    logoSource: {
      type: String,
      default: '',
    },
    installationName: {
      type: String,
      default: '',
    },
    accountId: {
      type: Number,
      default: 0,
    },
    menuItems: {
      type: Array,
      default: () => [],
    },
    activeMenuItem: {
      type: String,
      default: '',
    },
    menuConfig: {
      type: Object,
      default: () => { },
    },
    currentRole: {
      type: String,
      default: '',
    },
    labels: {
      type: Array,
      default: () => [],
    },
    inboxes: {
      type: Array,
      default: () => [],
    },
    teams: {
      type: Array,
      default: () => [],
    },
    customViews: {
      type: Array,
      default: () => [],
    },
    isOnChatwootCloud: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      helpDocsURL: wootConstants.DOCS_URL,
      showOptionsMenu: false,
      openIndexes: [], // Armazena quais submenus estão abertos
      sidebar
    };
  },
  mounted() {
    this.sidebar.dispatch("checkScreenSize");
    window.addEventListener("resize", () => this.sidebar.dispatch("checkScreenSize"));
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => this.sidebar.dispatch("checkScreenSize"));
  },
  methods: {
    frontendURL,
    toggleOptions() {
      this.showOptionsMenu = !this.showOptionsMenu;
    },
    toggleAccountModal() {
      this.$emit('toggle-accounts');
    },
    toggleSupportChatWindow() {
      window.$chatwoot.toggle();
    },
    openNotificationPanel() {
      this.$track(ACCOUNT_EVENTS.OPENED_NOTIFICATIONS);
      this.$emit('open-notification-panel');
    },
    toggleSubmenu(index) {
      const pos = this.openIndexes.indexOf(index);
      if (pos === -1) {
        if (this.openIndexes.length > 0)
          this.openIndexes = []
        // Se não estiver no array, adiciona (abre o submenu)
        this.openIndexes.push(index);
      } else {
        // Se já estiver no array, remove (fecha o submenu)
        this.openIndexes.splice(pos, 1);
      }
      //console.log("menuConfig",this.inboxes)
    },
    showAddLabelPopup() {
      this.$emit('add-label');
    },
    toggleAccountModal() {
      this.$emit('toggle-accounts');
    },
    showNewLink(featureFlag) {
      return this.isFeatureEnabledonAccount(this.accountId, featureFlag);
    },
  },
  computed: {
    ...mapGetters({
      isFeatureEnabledonAccount: 'accounts/isFeatureEnabledonAccount',
    }),
    hasSecondaryMenu() {
      return this.menuConfig.menuItems && this.menuConfig.menuItems.length;
    },
    contactCustomViews() {
      return this.customViews.filter(view => view.filter_type === 'contact');
    },
    accessibleMenuItems() {
      if (!this.currentRole) {
        return [];
      }
      const menuItemsFilteredByRole = this.menuConfig.menuItems.filter(
        menuItem =>
          window.roleWiseRoutes[this.currentRole].indexOf(
            menuItem.toStateName
          ) > -1
      );
      return menuItemsFilteredByRole.filter(item => {
        if (item.showOnlyOnCloud) {
          return this.isOnChatwootCloud;
        }
        return true;
      });
    },
    inboxSection() {
      return {
        icon: 'folder',
        label: 'INBOXES',
        hasSubMenu: true,
        newLink: this.showNewLink(FEATURE_FLAGS.INBOX_MANAGEMENT),
        newLinkTag: 'NEW_INBOX',
        key: 'inbox',
        toState: frontendURL(`accounts/${this.accountId}/settings/inboxes/new`),
        toStateName: 'settings_inbox_new',
        newLinkRouteName: 'settings_inbox_new',
        children: this.inboxes
          .map(inbox => ({
            id: inbox.id,
            label: inbox.name,
            truncateLabel: true,
            toState: frontendURL(
              `accounts/${this.accountId}/inbox/${inbox.id}`
            ),
            type: inbox.channel_type,
            phoneNumber: inbox.phone_number,
            reauthorizationRequired: inbox.reauthorization_required,
          }))
          .sort((a, b) =>
            a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
          ),
      };
    },
    labelSection() {
      return {
        icon: 'number-symbol',
        label: 'LABELS',
        hasSubMenu: true,
        newLink: this.showNewLink(FEATURE_FLAGS.TEAM_MANAGEMENT),
        newLinkTag: 'NEW_LABEL',
        key: 'label',
        toState: frontendURL(`accounts/${this.accountId}/settings/labels`),
        toStateName: 'labels_list',
        showModalForNewItem: true,
        modalName: 'AddLabel',
        dataTestid: 'sidebar-new-label-button',
        children: this.labels.map(label => ({
          id: label.id,
          label: label.title,
          color: label.color,
          truncateLabel: true,
          toState: frontendURL(
            `accounts/${this.accountId}/label/${label.title}`
          ),
        })),
      };
    },
    contactLabelSection() {
      return {
        icon: 'number-symbol',
        label: 'TAGGED_WITH',
        hasSubMenu: true,
        key: 'label',
        newLink: this.showNewLink(FEATURE_FLAGS.TEAM_MANAGEMENT),
        newLinkTag: 'NEW_LABEL',
        toState: frontendURL(`accounts/${this.accountId}/settings/labels`),
        toStateName: 'labels_list',
        showModalForNewItem: true,
        modalName: 'AddLabel',
        children: this.labels.map(label => ({
          id: label.id,
          label: label.title,
          color: label.color,
          truncateLabel: true,
          toState: frontendURL(
            `accounts/${this.accountId}/labels/${label.title}/contacts`
          ),
        })),
      };
    },
    teamSection() {
      return {
        icon: 'people-team',
        label: 'TEAMS',
        hasSubMenu: true,
        newLink: this.showNewLink(FEATURE_FLAGS.TEAM_MANAGEMENT),
        newLinkTag: 'NEW_TEAM',
        key: 'team',
        toState: frontendURL(`accounts/${this.accountId}/settings/teams/new`),
        toStateName: 'settings_teams_new',
        newLinkRouteName: 'settings_teams_new',
        children: this.teams.map(team => ({
          id: team.id,
          label: team.name,
          truncateLabel: true,
          toState: frontendURL(`accounts/${this.accountId}/team/${team.id}`),
        })),
      };
    },
    foldersSection() {
      return {
        icon: 'folder',
        label: 'CUSTOM_VIEWS_FOLDER',
        hasSubMenu: true,
        key: 'custom_view',
        children: this.customViews
          .filter(view => view.filter_type === 'conversation')
          .map(view => ({
            id: view.id,
            label: view.name,
            truncateLabel: true,
            toState: frontendURL(
              `accounts/${this.accountId}/custom_view/${view.id}`
            ),
          })),
      };
    },
    contactSegmentsSection() {
      return {
        icon: 'folder',
        label: 'CUSTOM_VIEWS_SEGMENTS',
        hasSubMenu: true,
        key: 'custom_view',
        children: this.customViews
          .filter(view => view.filter_type === 'contact')
          .map(view => ({
            id: view.id,
            label: view.name,
            truncateLabel: true,
            toState: frontendURL(
              `accounts/${this.accountId}/contacts/custom_view/${view.id}`
            ),
          })),
      };
    },
    additionalSecondaryMenuItems() {
      let conversationMenuItems = [this.inboxSection, this.labelSection];
      let contactMenuItems = [this.contactLabelSection];
      if (this.teams.length) {
        conversationMenuItems = [this.teamSection, ...conversationMenuItems];
      }
      if (this.customViews.length) {
        conversationMenuItems = [this.foldersSection, ...conversationMenuItems];
      }
      if (this.contactCustomViews.length) {
        contactMenuItems = [this.contactSegmentsSection, ...contactMenuItems];
      }
      return {
        conversations: conversationMenuItems,
        contacts: contactMenuItems,
      };
    },
  },
};
</script>

<style scoped>
.property-1-variant-5 {
  width: 256px;
  transition: width 0.3s ease, background 0.3s ease;
}

.property-1-variant-5.collapsed {
  width: 80px;
}

.property-1-variant-5.collapsed .conversas {
  display: none;
}

.property-1-variant-5:hover {
  width: 256px;
  /*background: rgba(0, 0, 0, 0.1);  Leve escurecimento */
}


.property-1-variant-5,
.property-1-variant-5 * {
  box-sizing: border-box;
}

.property-1-variant-5 {
  border-style: solid;
  border-color: #e4e4e7;
  border-width: 0px 1px 0px 0px;
  width: 256px;
  position: relative;
}

.background {
  /*font-family: "Inter-Regular", sans-serif;*/
  font-size: 14px;
}

.list-item {
  height: 48px;
  position: absolute;
  right: 8px;
  left: 8px;
  top: 8px;
}

.button-custom-menu {
  border-radius: 6px;
  height: 48px;
  position: absolute;
  right: 0px;
  left: 0px;
  top: 0px;
  overflow: hidden;
}

.logo-thumbnail-svg {
  width: 46px;
  height: 32px;
  position: absolute;
  left: 8px;
  top: 50%;
  translate: 0 -50%;
  overflow: hidden;
}

.container {
  position: absolute;
  right: 32px;
  left: 48px;
  bottom: 23.25px;
  top: 7.25px;
  overflow: hidden;
}

.container2 {
  position: absolute;
  right: 32px;
  left: 48px;
  bottom: 7.25px;
  top: 24.75px;
  overflow: hidden;
}

.enterprise {
  color: #3aaa35;
  /*font-family: "Inter-Regular", sans-serif;*/
  font-size: 14px;
  font-weight: 300;
}

.svg {
  width: 16px;
  height: 16px;
  position: absolute;
  right: 8px;
  top: 50%;
  translate: 0 -50%;
  overflow: visible;
}

.container3 {
  overflow: auto;
}

.servi-os {
  color: rgba(63, 63, 70, 0.7);
  text-align: left;
  /*font-family: "Inter-Regular", sans-serif;*/
  font-size: 13px;
  padding: 0.7rem;
}

.frame-427320933 {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.item {
  align-self: stretch;
  flex-shrink: 0;
  height: 32px;
  position: relative;
  cursor: pointer;
}

.button-custom {
  display: flex;
  align-items: center;
  border-radius: 6px;
  overflow: hidden;
  cursor: pointer;
}

.svg2 {
  width: 16px;
  height: 16px;
  position: absolute;
  left: 8px;
  top: 50%;
  translate: 0 -50%;
  overflow: visible;
}

.svg3 {
  width: 16px;
  height: 16px;
  position: absolute;
  right: -8px;
  top: calc(50% - 181px);
  overflow: visible;
}

.frame-427320935 {
  display: flex;
  align-items: center;
}

.conversas {
  color: #18181b;
  /*font-family: "Inter-Regular", sans-serif;*/
}

.svg4 {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  position: relative;
  overflow: visible;
}

.svg5 {
  width: 16px;
  height: 16px;
  position: absolute;
  left: 8px;
  top: 50%;
  translate: 0 -50%;
  overflow: visible;
}

.contatos {
  color: #3f3f46;
  text-align: left;
  /*font-family: "Inter-Regular", sans-serif;*/
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  position: absolute;
  left: 32px;
  top: 6px;
  width: 82px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.svg6 {
  width: 16px;
  height: 16px;
  position: absolute;
  right: 8px;
  top: 50%;
  translate: 0 -50%;
  overflow: visible;
}

.svg7 {
  width: 16px;
  height: 16px;
  position: absolute;
  left: 8px;
  top: 50%;
  translate: 0 -50%;
  overflow: visible;
}

.relat-rios {
  color: #3f3f46;
  text-align: left;
  /*font-family: "Inter-Regular", sans-serif;*/
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  position: absolute;
  left: 32px;
  top: 6px;
  width: 127px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.svg8 {
  width: 16px;
  height: 16px;
  position: absolute;
  right: 8px;
  top: 50%;
  translate: 0 -50%;
  overflow: visible;
}

.item2 {
  padding: 0.7rem;
}

.svg9 {
  width: 16px;
  height: 16px;
  position: absolute;
  left: 8px;
  top: 50%;
  translate: 0 -50%;
  overflow: visible;
}

.campanhas {
  color: #3f3f46;
  text-align: left;
  /*font-family: "Inter-Regular", sans-serif;*/
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  position: absolute;
  left: 32px;
  top: 6px;
  width: 97px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.svg10 {
  width: 16px;
  height: 16px;
  position: absolute;
  right: -8px;
  top: 50%;
  translate: 0 -50%;
  transform: translate(-16px, 0px);
  overflow: visible;
}

.list2 {
  border-style: solid;
  border-color: #e5e7eb;
  border-width: 0px 0px 0px 1px;
}

.frame-427320936 {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  justify-content: flex-start;
  width: 180px;
  position: absolute;
  left: 0px;
  top: 0px;
}

.item-link {
  border-radius: 6px;
  align-self: stretch;
  flex-shrink: 0;
  height: 28px;
  position: relative;
  overflow: hidden;
}

.svg11 {
  width: 16px;
  height: 16px;
  position: absolute;
  left: 8px;
  top: 50%;
  translate: 0 -50%;
  overflow: visible;
}

.central-de-ajuda {
  color: #3f3f46;
  text-align: left;
  /*font-family: "Inter-Regular", sans-serif;*/
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  position: absolute;
  left: 32px;
  top: 6px;
  width: 139px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.svg12 {
  width: 16px;
  height: 16px;
  position: absolute;
  left: 8px;
  top: 50%;
  translate: 0 -50%;
  overflow: visible;
}

.configura-es {
  color: #3f3f46;
  text-align: left;
  /*font-family: "Inter-Regular", sans-serif;*/
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  position: absolute;
  left: 32px;
  top: 6px;
  width: 139px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.svg13 {
  width: 16px;
  height: 16px;
  position: absolute;
  right: 8px;
  top: 50%;
  translate: 0 -50%;
  overflow: visible;
}

.list-item2 {
  align-self: stretch;
  flex-shrink: 0;
  height: 48px;
  position: relative;
}

.frame-427320939 {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: 7.5px;
  top: 11px;
}

.svg14 {
  flex-shrink: 0;
  width: 26px;
  height: 26px;
  position: relative;
  overflow: visible;
}

.container4 {
  flex-shrink: 0;
  width: 159px;
  height: 17.5px;
  position: relative;
  overflow: hidden;
}

.notifica-es {
  color: #3f3f46;
  text-align: left;
  /*font-family: "Inter-Regular", sans-serif;*/
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  position: absolute;
  left: 0px;
  top: 1.75px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.nome-do-usu-rio {
  color: #000000;
  text-align: left;
  /*font-family: "Inter-Regular", sans-serif;*/
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  position: absolute;
  left: 0px;
  top: 1.75px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.container5 {
  width: 159px;
  height: 17.5px;
  position: absolute;
  left: 0px;
  top: 50%;
  translate: 0 -50%;
  overflow: hidden;
}

.setor {
  color: #000000;
  text-align: left;
  /*font-family: "Inter-Regular", sans-serif;*/
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  position: absolute;
  left: 0px;
  top: 1.75px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.svg15 {
  width: 16px;
  height: 16px;
  position: absolute;
  right: 8px;
  top: 50%;
  translate: 0 -50%;
  overflow: visible;
}

.container6 {
  border-radius: 8px;
  width: 32px;
  height: 32px;
  position: absolute;
  left: 8px;
  top: 50%;
  translate: 0 -50%;
  overflow: hidden;
}

.shadcn-jpg {
  position: absolute;
  right: 0px;
  left: 0px;
  bottom: 0px;
  top: 0px;
  overflow: hidden;
  object-fit: cover;
}

.button-custom-toggle-sidebar {
  width: 2px;
  position: absolute;
  left: calc(50% - -127px);
  bottom: 0px;
  top: 0px;
}

.rotated {
  transform: rotate(90deg);
}

/* Quando a tag HTML está no tema escuro */
html[style="color-scheme: dark;"] .frame-427320933 {
  background-color: #121212;
  /* Cor de fundo para o contêiner no tema escuro */
}
html[style="color-scheme: dark;"] .property-1-variant-5 {
  background-color: #121212;
  color: #fff;
  /* Cor de fundo para o contêiner no tema escuro */
}
html[style="color-scheme: dark;"] .item2 {
  background-color: #1e1e1e;
  /* Cor de fundo para os itens do menu no tema escuro */
  color: #fff;
  /* Cor do texto */
}

html[style="color-scheme: dark;"] .frame-427320935 {
  border-color: #333;
  /* Cor da borda para os itens do menu no tema escuro */
}

html[style="color-scheme: dark;"] .conversas {
  color: #ccc;
  /* Cor do texto da classe 'conversas' no tema escuro */
}

html[style="color-scheme: dark;"] .fas.fa-chevron-right {
  color: #ccc;
  /* Cor do ícone de setinha no tema escuro */
}

/* Quando a tag HTML está no tema claro */
html[style="color-scheme: light;"] .frame-427320933 {
  background-color: white;
  /* Cor de fundo para o contêiner no tema claro */
}

html[style="color-scheme: light;"] .item2 {
  /* Cor de fundo para os itens do menu no tema claro */
  color: #000;
  /* Cor do texto */
}

html[style="color-scheme: light;"] .frame-427320935 {
  border-color: #ddd;
  /* Cor da borda para os itens do menu no tema claro */
}

html[style="color-scheme: light;"] .conversas {
  color: #333;
  /* Cor do texto da classe 'conversas' no tema claro */
}

html[style="color-scheme: light;"] .fas.fa-chevron-right {
  color: #333;
  /* Cor do ícone de setinha no tema claro */
}
html[style="color-scheme: dark;"] .servi-os {
  color: #ccc !important;
  /* Cor do texto da classe 'conversas' no tema escuro */
}
html[style="color-scheme: dark;"] .icone {
  color: #ccc !important;
  /* Cor do texto da classe 'conversas' no tema escuro */
}
</style>
