<template>
  <!--
  <woot-button v-tooltip.right="$t(`SIDEBAR.PROFILE_SETTINGS`)" class=""
    @click="handleClick">
  </woot-button>
  -->
  <div class="flex w-full agente" style="justify-content: space-between;cursor:pointer;align-items: center" @click="handleClick">
    <div class="flex" style="align-items: center;gap: 0.5rem;">
      <div>
        <thumbnail :src="currentUser.avatar_url" :username="currentUser.name" :status="statusOfAgent"
          should-show-status-always size="32px" />
      </div>
      <div class="background" v-if="!sidebar.state.isCollapsed || sidebar.state.isHovered">
        <div class="flex" style="flex-direction: column;">
          <div style="font-weight: bold;">
            {{ currentUser.name }}
          </div>
          <div>
            Setor
          </div>
        </div>
      </div>
    </div>
    <div style="display: flex;flex-direction: column;" v-if="!sidebar.state.isCollapsed || sidebar.state.isHovered">
      <div style="font-size: small;height: 8px;color:rgb(63, 63, 70)">
        <i class="fas fa-chevron-up"></i>
      </div>
      <div style="font-size: small;color:rgb(63, 63, 70)">
        <i class="fas fa-chevron-down"></i>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Thumbnail from '../../widgets/Thumbnail.vue';
import { sidebarStore as sidebar } from "./store";
export default {
  data() {
    return {
      sidebar
    };
  },
  components: {
    Thumbnail,
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      currentUserAvailability: 'getCurrentUserAvailability',
    }),
    statusOfAgent() {
      return this.currentUserAvailability || 'offline';
    },
  },
  mounted() {
    this.sidebar.dispatch("checkScreenSize");
    window.addEventListener("resize", () => this.sidebar.dispatch("checkScreenSize"));
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => this.sidebar.dispatch("checkScreenSize"));
  },
  methods: {
    handleClick() {
      this.$emit('toggle-menu');
    },
  },
};
</script>

<style scoped lang="scss">
.current-user {
  align-items: center;
  display: flex;
  border-radius: 50%;
  border: 2px solid var(--white);
}

.background {
  /*font-family: "Inter-Regular", sans-serif;*/
  font-size: 14px;
}
</style>
<style scoped>
html[style="color-scheme: dark;"] .agente {
  color: #ccc !important;
  /* Cor do texto da classe 'conversas' no tema escuro */
}
</style>
