<template>
    <div class="success-container">
        <div class="success-content">
            <i class="fab fa-whatsapp whatsapp-icon"></i>
            <h1 class="success-title">Conta criada com sucesso!</h1>
            <p class="success-message">
                Parabéns pela criação da conta no GovChat! <br />
                Dê início à sua jornada de Atendimento com WhatsApp.
            </p>
            <button class="btn-configure" @click="configureInbox">Configurar a Caixa de Entrada</button>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        configureInbox() {
            // Lógica para configurar a caixa de entrada
            this.$emit("redirectSkip")
        },
    },
};
</script>

<style scoped>
.success-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 95vh;
    background-color: #fff;
    /* Fundo branco */
    color: #212529;
    /* Texto escuro */
}

.success-content {
    text-align: center;
}

.whatsapp-icon {
    font-size: 50px;
    /* Tamanho do ícone */
    color: #25d366;
    /* Cor verde do WhatsApp */
    margin-bottom: 20px;
}

.success-title {
    font-size: 24px;
    color: #212529;
    /* Cor roxa do título */
    margin-bottom: 10px;
}

.success-message {
    font-size: 16px;
    line-height: 1.5;
    color: #4b4b4b;
    /* Cor cinza para a mensagem */
    margin-bottom: 30px;
}

.btn-configure {
    background-color: #28a745;
    /* Fundo verde */
    color: #fff;
    /* Texto branco */
    border: none;
    border-radius: 5px;
    padding: 12px 24px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btn-configure:hover {
    background-color: #218838;
    /* Fundo verde mais escuro ao passar o mouse */
}
</style>