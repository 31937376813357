import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export const sidebarStore = new Vuex.Store({
  state: {
    isCollapsed: window.innerWidth <= 768, // Define o estado inicial
    isHovered: false,
  },
  mutations: {
    SET_COLLAPSED(state, value) {
      state.isCollapsed = value;
    },
    SET_HOVERED(state, value) {
      state.isHovered = value;
    },
    toogleSider(state){
      /*
      state.isHovered = !state.isHovered;
      */
     state.isCollapsed = !state.isCollapsed
    }
  },
  actions: {
    checkScreenSize({ commit }) {
      commit("SET_COLLAPSED", window.innerWidth <= 768);
    },
    toogleSider({ commit }) {
      commit("toogleSider");
    },
  },
});
