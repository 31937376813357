<template>
  <div class="w-full flex">
    <div class="whatsapp-business" v-if="register && !success">
      <h2 class="title">WhatsApp Business</h2>
      <div class="steps-container">
        <div class="step-item active skip">
          <span class="step-number">1</span>
          <span class="step-label">Vamos começar!</span>
        </div>
        <div class="step-item skip">
          <span class="step-number">2</span>
          <span class="step-label">Criar Conta</span>
        </div>
        <div class="step-item skip">
          <span class="step-number">3</span>
          <span class="step-label">Selecionar uma linha</span>
        </div>
        <div class="step-item finalizado">
          <span class="step-number">4</span>
          <span class="step-label">Parabéns!</span>
        </div>
      </div>
      <h3 class="subtitle">Vamos começar!</h3>
      <p class="description">
        Conecte o WhatsApp Business e comece a conversar com seus clientes.
        <br />
        Iremos guiá-lo através das seguintes etapas:
      </p>
      <ul class="steps-list">
        <li>
          <span class="list-number">1</span>
          Crie um Facebook Business Manager e uma conta WhatsApp Business.
        </li>
        <li>
          <span class="list-number">2</span>
          Conecte e configure a linha de WhatsApp.
        </li>
      </ul>
      <h4 class="prerequisites-title">Pré-requisitos</h4>
      <div class="prerequisites">
        <div class="flex">
          <span class="icon-circle">
            <i class="fas fa-phone"></i>
          </span>
          <p class="description">
            Linha telefônica para se conectar ao WhatsApp: uma linha telefônica
            totalmente funcional instalada em um celular capaz de receber um código
            de verificação por SMS ou chamada de voz.
          </p>
        </div>
        <div class="flex w-full" style="justify-content:flex-end">
          <button class="facebook-button" @click="launchWhatsAppSignup">
            <i class="fa-brands fa-facebook"></i> Continuar com Facebook
          </button>
        </div>
      </div>
    </div>
    <div v-else-if="error && !register" class="w-full">
      <RegisterFailZap @tentarNovamente="tentarNovamente" :error="error"/>
    </div>
    <div v-else-if="success && !register" class="w-full">
      <SuccessZap @redirectSkip="redirectSkip"/>
    </div>
    <BlockUI v-if="load" :message="messageLoad" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import alertMixin from "shared/mixins/alertMixin";
import { required } from "vuelidate/lib/validators";
import { isPhoneE164OrEmpty, isNumber } from "shared/helpers/Validators";
import { initializeFacebookSDK } from "shared/helpers/facebookSDK";
import { log } from "logrocket";
import router from '../../../../index';
import serviceHttp from "shared/helpers/httpConfig";
import BlockUI from "./blockUi.vue";
import RegisterFailZap from "./registerFailZap.vue";
import SuccessZap from "./successZap.vue";
import { setTimeout } from "core-js";

const FACEBOOK_API_VERSION = "v21.0";
const FACEBOOK_BASE_URL = `https://graph.facebook.com/${FACEBOOK_API_VERSION}`;
const CLIENT_ID = "1754127442093667";
const CLIENT_SECRET = "1ed8e65d72de00e428a55964415ebf69";
export default {
  mixins: [alertMixin],
  components: {
    BlockUI,
    RegisterFailZap,
    SuccessZap
  },
  data() {
    return {
      inboxName: "",
      load: false,
      register: true,
      error: false,
      success: false,
      messageLoad: "Processando aguarde...",
      phoneNumber: "",
      whatsappChannel_id: undefined,
      apiKey: "",
      phoneNumberId: "",
      businessAccountId: "",
      sessionInfo: null,
      sdkResponse: null,
      debugMode: process.env.NODE_ENV !== "production",
      authCode: "",
    };
  },
  computed: {
    ...mapGetters({ uiFlags: "inboxes/getUIFlags" }),
  },
  validations: {
    inboxName: { required },
    phoneNumber: { required, isPhoneE164OrEmpty },
    apiKey: { required },
    phoneNumberId: { required, isNumber },
    businessAccountId: { required, isNumber },
  },
  methods: {
    handleFacebookMessage(event) {
      if (!this.isValidFacebookOrigin(event.origin)) return;

      try {
        const data = JSON.parse(event.data);
        //console.log(data);
        if (data.type === "WA_EMBEDDED_SIGNUP" && data.event === "FINISH") {
          this.processFacebookData(data.data);
        }
        this.sessionInfo = JSON.stringify(data, null, 2);
      } catch (error) {
        //console.error(error);
      }
    },
    isValidFacebookOrigin(origin) {
      return (
        origin === "https://www.facebook.com" ||
        origin === "https://web.facebook.com"
      );
    },
    processFacebookData({ phone_number_id, waba_id }) {
      this.phoneNumberId = phone_number_id;
      this.businessAccountId = waba_id;
    },
    fbLoginCallback(response) {
      if (!response.authResponse) {
        console.error("Facebook authentication failed");
        return;
      }

      this.sdkResponse = response;
      this.authCode = response.authResponse.code;

      if (!this.phoneNumberId || !this.businessAccountId) {
        console.error("Facebook authentication businessAccountId or phoneNumberId");
        return;
      }
      const payload = {
        phone_number_id: this.phoneNumberId,
        waba_id: this.businessAccountId,
        code: this.authCode
      }
      this.sendToAPI(payload);
    },
    launchWhatsAppSignup() {
      //console.log('Initiating WhatsApp signup...');
      FB.login(this.fbLoginCallback, {
        config_id: "1293299781862061",
        response_type: "code",
        override_default_response_type: true,
        extras: {
          setup: {},
          featureType: "",
          sessionInfoVersion: "2",
        },
      });
    },
    toogleClass(clas, action, find) {
      // Seleciona todas as divs com a classe "skip"
      const skipDivs = document.querySelectorAll(`.${find}`);

      // Adiciona a classe "active" a cada uma
      skipDivs.forEach(div => {
        if (action == "add")
          div.classList.add(clas);
        else
          div.classList.remove(clas);
      });
    },
    async sendToAPI(payload) {
      this.load = true
      this.toogleClass('active', 'add', 'skip')
      try {
        const response = await serviceHttp.post("/register", payload);
        console.log("response", response);
        this.toogleClass('active', 'add', 'finalizado')
        this.createChannel(response)
      } catch (error) {
        console.log("error:", error);
        this.load = false
        this.register = false
        this.error = "Erro interno no servidor."
        this.toogleClass('active', 'remove', 'skip')
      }
    },
    handleAPIError(error) {
      console.error("Error in API request:", error);
      this.showAlert(`An error occurred: ${error.message}. Please try again.`);
    },
    tentarNovamente() {
      this.register = true
    },
    redirectSkip() {
      router.replace({
        name: 'settings_inboxes_add_agents',
        params: {
          page: 'new',
          inbox_id: this.whatsappChannel_id,
        },
      });
    },
    async createChannel(dados) {
      this.messageLoad = "Registrando caixa de entrada..."
      try {
        const whatsappChannel = await this.$store.dispatch(
          'inboxes/createChannel',
          {
            name: dados.verified_name,
            channel: {
              type: 'whatsapp',
              phone_number: dados.display_phone_number,
              provider: 'whatsapp_cloud',
              provider_config: {
                api_key: dados.access_token,
                phone_number_id: dados.phone_number_id,
                business_account_id: dados.waba_id,
              },
            },
          }
        );
        console.log("whatsappChannel", whatsappChannel);
        this.whatsappChannel_id = whatsappChannel.id
        this.load = false
        this.success = true
        this.register = false
      } catch (error) {
        console.log(error)
        this.load = false
        this.register = false
        this.error = "Erro interno aplicação"
      }
    },
  },
  mounted() {
    initializeFacebookSDK(CLIENT_ID, "v21.0");
    window.addEventListener("message", this.handleFacebookMessage);
  },
  beforeDestroy() {
    window.removeEventListener("message", this.handleFacebookMessage);
  },
};
</script>

<style scoped>
.whatsapp-business {
  font-family: "Arial", sans-serif;
  max-width: 700px;
  margin: auto;
  text-align: left;
  border: 1px solid #e0e0e0;
  padding: 30px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.title {
  font-size: 22px;
  color: #25d366;
  margin-bottom: 20px;
  font-weight: bold;
  text-align: center;
}

/* Etapas do progresso */
.steps-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  border-bottom: 2px solid #e0e0e0;
  padding-bottom: 10px;
}

.step-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex: 1;
}

.step-item:not(:last-child) {
  margin-right: 10px;
}

.step-number {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #e0e0e0;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-bottom: 5px;
}

.step-label {
  font-size: 14px;
  color: #666;
}

.step-item.active .step-number {
  background-color: #25d366;
  color: #ffffff;
}

.step-item.active .step-label {
  color: #25d366;
}

.subtitle {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
}

.description {
  font-size: 14px;
  line-height: 1.6;
  color: #555;
  margin-bottom: 20px;
}

.steps-list {
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
  color: #333;
  font-size: 14px;
  margin-left: 0 !important;
}

.steps-list li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.list-number {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #25d366;
  color: #ffffff;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.prerequisites-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

.prerequisites {
  display: flex;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.icon-circle {
  width: 48px;
  height: 25px;
  border-radius: 50%;
  background-color: #25d366;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  font-size: 13px;
}

.facebook-button {
  background-color: #1877f2;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.facebook-icon {
  margin-right: 8px;
}

.facebook-button:hover {
  background-color: #155dbf;
}
</style>