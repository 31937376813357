import userStore from '../../../javascript/dashboard/store';
const BASE_URL = "https://meta-service.govchat.tech/api"; // Botar na ENV
const defaultHeaders = {

};

const request = async (url, method, body = null, headers = defaultHeaders) => {
  const currentUser = userStore.getters.getCurrentUser;
  headers = {
    "Content-Type": "application/json",
    "x-chatwoot-domain": window.location.origin,
    "x-chatwoot-domain-token": currentUser.access_token
  }
  try {
    const options = {
      method,
      headers,
    };
    if (body) {
      options.body = JSON.stringify(body);
    }

    const response = await fetch(`${BASE_URL}${url}`, options);

    // Tente extrair o corpo da resposta (mesmo em caso de erro)
    const responseBody = await response.json().catch(() => null);    
    if (!response.ok) {
      // Lançar erro com informações do status e do corpo da resposta
      const error = new Error(`Erro: ${response.status} - ${response.statusText}`);
      error.details = responseBody; // Inclui os detalhes do erro
      throw responseBody;
    }

    return responseBody;
  } catch (error) {
    console.error("Erro na requisição:", error);
    throw error;
  }
};

const apiService = {
  get(endpoint, params = {}, headers = defaultHeaders) {
    const queryString = new URLSearchParams(params).toString();
    const fullUrl = queryString ? `${endpoint}?${queryString}` : endpoint;
    return request(fullUrl, "GET", null, headers);
  },

  post(endpoint, body, headers = defaultHeaders) {
    return request(endpoint, "POST", body, headers);
  },

  put(endpoint, body, headers = defaultHeaders) {
    return request(endpoint, "PUT", body, headers);
  },
};

export default apiService;
