<template>
    <div class="error-container">
        <div class="error-content">
            <img class="error-image" src="./assets/robot.png" alt="Erro" />
            <h1 class="error-title">Erro: Não foi possível criar a conta. Tente novamente.</h1>
            <p class="error-description">
                Ops! Parece que houve um problema ao criar sua conta de WhatsApp no Meta. <br />
                Verifique suas informações e tente novamente. {{ error }}
            </p>
            <div class="error-actions">
                <button class="btn btn-back" @click.capture="goBack">Voltar</button>
                <button class="btn btn-retry" @click="retry">Tentar novamente</button>
            </div>
        </div>
    </div>
</template>

<script>
import { error } from 'logrocket';
import router from '../../../../../routes/index';
export default {
    props: {
        error: {
            type: String,
            default: undefined,
        }
    },
    methods: {
        goBack() {
            router.go(-1)
        },
        retry() {
            // Lógica para tentar novamente
            this.$emit('tentarNovamente');
        },
    },
};
</script>

<style scoped>
.error-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 95vh;
    background-color: #f9f9f9;
}

.error-content {
    text-align: center;
    max-width: 600px;
}

.error-image {
    width: 170px;
    margin-bottom: 20px;
}

.error-title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}

.error-description {
    font-size: 16px;
    color: #666;
    margin-bottom: 30px;
}

.error-actions {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.btn {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.btn-back {
    background-color: #007bff;
    color: #fff;
}

.btn-retry {
    background-color: #28a745;
    color: #fff;
}

.btn:hover {
    opacity: 0.9;
}
</style>